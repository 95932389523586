import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Practitioner from "../../components/practitioner"
import FormLink from "../../components/formLink"

const data = [
	{
		name: "Dr Sylvia Guest",
		address: "Broadway Medical, ELLENBROOK",
		phone: "9296 8324",
	},
	{
		name: "Dr Mark Steiler",
		address: "St Francis Medical, SUBIACO",
		phone: "9382 3334",
	},
]

const GPs = () => (
	<Layout>
		<SEO title="General Practitioners (GPs) experienced in lupus and/or autoimmune conditions" />
		<h1>General Practitioners</h1>
		{data.map((practitioner, i) => (
			<Practitioner key={i} {...practitioner} />
		))}
		<FormLink text="If you have any GP suggestions or edits, " />
	</Layout>
)

export default GPs
